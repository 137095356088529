import React from 'react'

const Contactus = () => {
  return (
    <div>
   <h1 className='text-blue-600 mt-5'>THIS IS CONTACT</h1>  
    </div>
  )
}

export default Contactus
