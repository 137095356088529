import React from 'react'

const Aboutus = () => {
  return (
    <div>
      <h1 className=' font-serif mt-5'>This is About   </h1>
    </div>
  )
}

export default Aboutus
